
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/

@import
	"ecco";

/*
* SHARED
*/

@import
	"shared/vars",
	"shared/helpers",
	"shared/animations";

/*
* COMMON BLOCKS
*/

@import
	"common/_home";

/*
* DESIGN COMPONENTS
*/

@import
	//"blocks/social",
	"blocks/tabs",
	"blocks/card",
	"blocks/board-card",
	"blocks/abo",
	"blocks/homepage-events",
	"blocks/blender";
	//"blocks/figure",
	//"blocks/detail";
