body {
	color: $color-base;
}

.header {
	@include min(600px) {
		background: transparent url(/images/local/v-202503271058/header-bg-right.png) no-repeat 100% 0;
	}
	@include min(1024px){
		&__bottom{
			margin-top: 2.5rem;
		}
	}
	@include min(1660px) {
		margin-bottom: 4.2rem;
	}
}

.introduction {
	padding-bottom: 3rem;

	.element-type-heading {
		line-height: 1;
		margin-bottom: 2rem;
		max-width: $max-width;

		span {
			color: $color-brand;
		}

		@include min(500px) {
			@include max(599px) {
				font-size: 3rem;
			}
		}
	}

	.more {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		margin: 3rem 0 0;
		text-align: left;
		&__button{
			min-width: 0px;
			flex-grow: 1;
		}

		@include min(1281px){
			&__button{
				min-width: 250px;
				flex-grow: 0;
			}
		}
	}

	@include min(1280px) {
		padding: 8.4% 0 10%;
	}
}

.heading-rotary {
	position: relative;
	text-align: left;
	display: inline-flex;
	flex-direction: column;
	vertical-align: bottom;

	&__item {
		transition: rotate3d .75s ease-in-out, opacity .5s ease-in-out;
		transform: rotateX(-90deg);
		opacity: 0;
		height: 0;
		order: -1;
		transform-style: preserve-3d;

		&.active {
			height: auto;
			order: 0;
			animation: rotation 3s infinite;
			transform-style: preserve-3d;
		}
	}
}

@keyframes rotation {
	0% {
		transform: translateY(-50%);
		opacity: 0;
	}

	20%,
	80% {
		transform: translateY(0%);
		opacity: 1;
	}

	100% {
		transform: translateY(50%);
		opacity: 0;
	}
}

.section.-choose-variant {
	background-color: $color-brand;
	color: $color-light;

	.element-type-heading {
		color: $color-light;
		margin-bottom: 1.7rem;
	}

	.text-component {
		line-height: 1.5;
		margin-bottom: 3.7rem;
	}

	@include link() {
		font-weight: 800;
		color: $color-light;
	}

	.choose-variant {
		display: flex;
		flex-direction: column;
		padding: 8.5% 0 8%;

		&__halve {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			z-index: 50;

			&:nth-of-type(1) {
				border-bottom: 1px solid #cd4649;
				margin-bottom: 3.7rem;
			}

			&:nth-of-type(2) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 1rem;

				@include min(1280px) {
					flex-wrap: nowrap;
					flex-direction: row;
				}
			}
		}
		@include min(1024px) {
			max-width: 680px;
		}
		@include min(1280px) {
			max-width: 780px;
		}
	}

	.web-variant {
		max-width: 340px;

		.element-type-heading {
			display: flex;
			gap: 1rem;
			font-size: rem(50px);
			margin-bottom: 1.2rem;
			&::after{
				display: inline-block;
				content: "";
				width: 50px;
				height: 50px;
				transition: transform .3s ease-in-out;
				background: transparent url(/images/local/icons/v-202503271058/standard.svg) no-repeat 50% 50%;
			}
		}
		&.-premium .element-type-heading::after{
			background-image: url(/images/local/icons/v-202503271058/premium.svg);
		}

		.text-component {
			line-height: 1.6;
			margin-bottom: 2.1rem;
		}
	}
	@keyframes ToRight {
		0% 	{background-position-x: 90%;}
		30% 	{background-position-x: calc(90% + 3%);}
		55% 	{background-position-x: 90%;}
		65% 	{background-position-x: calc(90% + 2%);}
		75% 	{background-position-x: 90%;}
		90% 	{background-position-x: calc(90% + 1%);}
		100% 	{background-position-x: 90%;}
	}
	@keyframes FromRight {
		0% 	{background-position-x: 50%;}
		30% 	{background-position-x: calc(50% - 3%);}
		55% 	{background-position-x: 50%;}
		65% 	{background-position-x: calc(50% - 2%);}
		75% 	{background-position-x: 50%;}
		90% 	{background-position-x: calc(50% - 1%);}
		100% 	{background-position-x: 50%;}
	}

	@keyframes ToLeft {
		0% 	{background-position-x: 10%;}
		30%	{background-position-x: calc(10% - 3%);}
		55% 	{background-position-x: 10%;}
		65% 	{background-position-x: calc(10% - 2%);}
		75% 	{background-position-x: 10%;}
		90% 	{background-position-x: calc(10% - 1%);}
		100% 	{background-position-x: 10%;}
	}
	@keyframes FromLeft {
		0% 	{background-position-x: 50%;}
		30% 	{background-position-x: calc(50% + 3%);}
		55% 	{background-position-x: 50%;}
		65% 	{background-position-x: calc(50% + 2%);}
		75% 	{background-position-x: 50%;}
		90% 	{background-position-x: calc(50% + 1%);}
		100% 	{background-position-x: 50%;}
	}

	@include min(1024px) {
		position: relative;
		overflow: hidden;

		&::after {
			position: absolute;
			content: "";
			right: 0;
			bottom: 0;
			width: 1450px;
			height: 700px;
			background: transparent url(/images/local/v-202503271058/choose-variant-bg.jpg);
			background-repeat: no-repeat;
			background-position: 50% 0;
			transform-origin: 50% 100%;
			transition: background-position .6s;
			transform: translate(50%, 0%) rotate(-45deg);
			background-size: cover;
			animation-duration: .4s;
			animation-delay: .5s;
			animation-iteration-count: 1;
		}

		&.-standart {
			&::after {
				background-position-x: 10%;
				//animation-name: ToLeft;
			}
			.web-variant.-standart .element-type-heading::after{
				transform: scale(1.2);
			}
		}
		&.-from-standart {
			&::after {
				//animation-name: FromLeft;
			}
		}

		&.-premium {
			&::after {
				background-position-x: 90%;
				//animation-name: ToRight;
			}
			.web-variant.-premium .element-type-heading::after{
				transform: scale(1.2);
			}
		}
		&.-from-premium {
			&::after {
				//animation-name: FromRight;
			}
		}
	}
	@include min(1660px) {
		&::after {
			width: 2000px;
			height: 927px;
		}
	}
}


.additional__section {
	.images-container {
		img {
			transition: filter .15s ease-in;
			filter: grayscale(100);
		}

		@include link-over {
			img {
				filter: grayscale(0);
			}
		}
	}
}

.section.references {
	padding: 4em 0;
	background: transparent url(/images/local/v-202503271058/references-bg-top-right.png) no-repeat 100% 5%;

	@include min(900px) {
		ul.list {
			margin-left: -3.3333%;
			margin-right: -3.3333%;
		}
	}
	@include min(1400px) {
		padding: 9.4em 0 7.5em;
	}
}

section.-news{
	max-width: 600px;
	margin: 0 auto;
	padding-top: 0;
}

.news {
	h2.element-type-heading{
		font-size: rem(18px);
		font-weight: 800;
		color: $color-brand;
		text-transform: uppercase;
		font-family: $font-body;
		margin-bottom: 0.5rem;
		letter-spacing: -1px;
	}
	.list {
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		padding-right: 0;

		&__item {
			flex-grow: 0;
			display: flex;
			padding: 0;
			>div {
				width: 100%;
				display: flex;
				width: 100%;
				flex-wrap: wrap;
			}
		}

		.card {
			padding: 0;
			flex-grow: 1;
			&__title{
				margin-bottom: 1.25rem;
				color: $color-dark!important;
			}
			&__description{
				color: #838383!important;
			}
			@include link{
				text-decoration: underline;
			}
			@include link-over{
				text-decoration: none;
			}
			&:before{
				content: normal;
			}
		}
	}
}
