.homepage-events {
	.list {
		border-width: 10px 0;
		padding: 1rem 0 0;
		margin: 0 0 0;

		@include min(600px) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 0 4%;
		}
	}
}

.homepage-event {
	position: relative;

	&__image {
		position: relative;
		margin: 0;
		overflow: hidden;
		padding-bottom: 66.66667%;

		&:empty {
			background: lightgray encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="85px" fill="#fff" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g><path d="M881.1,132.5H118.9C59,132.5,10,181.5,10,241.4v517.3c0,59.9,49,108.9,108.9,108.9h762.2c59.9,0,108.9-49,108.9-108.9V241.4C990,181.5,941,132.5,881.1,132.5z M949.2,747.3c0,54.9-24.5,79.4-79.4,79.4H130.3c-54.9,0-79.4-24.5-79.4-79.4V252.7c0-54.9,24.5-79.4,79.4-79.4h739.5c54.9,0,79.4,24.5,79.4,79.4V747.3z M316.3,418.3L418.3,500l265.4-224.6l204.2,183.8v306.3H112.1V581.7L316.3,418.3z M193.8,234.6c-45.1,0-81.7,36.6-81.7,81.7s36.6,81.7,81.7,81.7s81.7-36.6,81.7-81.7S238.9,234.6,193.8,234.6z"/></g></svg>') no-repeat center center;
		}

		img {
			position: absolute;
			@include fill();
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform .4s;
		}
	}

	&__date {
		@include flex(df, fww, aic);
		font-size: rem(16px);
		font-weight: 700;
		padding: 1rem 0 0.5rem;

		&-dot {
			color: $color-brand;
		}

		&-fullday {
			display: inline-flex;

			&:not(:first-child)::before {
				content: " - ";
				margin: 0 0.25em;
			}
		}
	}

	&__body {
		@include flex(df, fdc, jcc);
		font-size: rem(14px);
	}

	&__link {
		margin-bottom: 0;

		&:after {
			position: absolute;
			content: "";
			@include fill;
		}
	}

	&__title {
		font-size: rem(22px);
		color: inherit;
	}

	&__more {
		margin-top: 2rem;
	}
	@include link(".homepage-event__link") {
		color: $color-tertiary;
		text-decoration: underline;
	}

	@include link-over(".homepage-event__link") {
		color: $color-brand;
		text-decoration: none;
	}
}
