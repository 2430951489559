.abo-news {
	position: relative;
	.centered {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 4rem;

		@include min(1180px) {
			flex-wrap: nowrap;
			gap: 2rem;

			.section.-news {
				max-width: 350px;
				margin: 0;
			}

			.section.-abo {
				position: static;
				max-width: 800px;
				padding: 1.9rem 20% 1.9rem 7%;
				margin-left: 0;

				&::before {
					top: -21%;
					right: -5%;
				}

			}
		}

		@include min(1280px) {
			margin-bottom: 8rem;
			.section.-abo {
				&::before {
					width: 466px;
					height: 446px;
				}
			}
		}

		@include min(1720px) {
			.section.-abo {
				padding-right: 12.2%;
				width: 100%;

				&::before {
					right: -0.4%;
				}
			}
		}
	}
}

.section.-abo {
	position: relative;
	padding: 1.9rem 0;

	@include min(750px) {
		padding: 1.9rem 240px 1.9rem 0;
		margin-left: -1rem;
		background-color: #fbfbfb;
		border-radius: 10px;
		.element-type-heading {
			padding: 0 1rem;
		}
		.gdpr-agreement {
			margin-left: 1rem;
			margin-right: 1rem;
		}

		.element-type-heading {
			font-size: rem(50px);
		}

		&::before {
			position: absolute;
			content: "";
			top: -10%;
			right: -7%;
			background: transparent url(/images/local/v-202503271058/abo-bg.png) no-repeat 50% 50% / cover;
			width: 370px;
			height: 352px;
		}
	}
}

.abo {
	z-index: 20;
	color: #838383;

	@include link() {
		font-weight: 600;
		color: #838383;
	}
	p{
		font-weight: 600;
		margin: 0.4rem 0 1.4rem;
		max-width: 20rem;
		@include min(750px) {
			margin: 0.4rem 1rem 1.4rem;
		}
	}

	&__form {
		.form__fields {
			position: relative;
		}

		.form__section {
			.label {
				position: absolute;
				top: 7.5px;
				left: 2.3rem;
				font-weight: 500;
				font-size: rem(12px);
				color: #a6a6a6;

				&__info {
					padding-left: 1rem;
				}
			}

			.form__button.-primary {
				position: absolute;
				top: 0;
				right: 1.25rem;
				font-size: 0;
				background: $color-brand url(/images/local/v-202503271058/arrow-right-w.png) no-repeat center center;
				height: 60px;
				width: 60px;
				min-height: 60px;
				border-radius: 10px;
			}

			.input {
				padding: 1em 5em 0.3em 1em;
				background-color: #fff;
				border-radius: 10px;
				border: 0;
				color: #4a4a4a;
				font-weight: 600;
				min-height: 60px;
			}

			p {
				margin: 0;
			}
		}
	}

}
