.tabs {
	font-size: rem(21px);
	line-height: 1.1;
	font-family: $font-body;
	text-transform: uppercase;
	display: none;

	&[data-tabs] > p {
		@extend %visually-hidden;
	}
}

.tablist {
	list-style-type: none;
	margin: 0 0 25px 0;
	padding: 0;
	display: flex;

	&[role="tablist"] .tablist__item::before {
		content: normal;
	}

	&__item {
		padding: 15px 30px;
		margin: 0;
		display: block;
		background-color: $tab-background;
		color: $tab-text;
		cursor: pointer;

		&:hover,
		&:focus {
			outline: 0;
		}

		&[aria-selected="true"] {
			cursor: default;
			background-color: $tab-selected-background;
			color: $tab-selected-text;
		}
	}
}

@include min(650px) {
	.tabs {
		display: block;
	}

	.tabs-sections .section {
		&[role="tabsection"] h2.element-type-heading,
		&[role="tabsection"] h3.element-type-heading {
			@include min(651px) {
				position: absolute;
				left: -9999em;
				top: 0;
				width: 0;
				height: 0;
				line-height: 0;
				overflow: hidden;
				outline: 0;
			}
		}

		&[aria-hidden="true"] {
			display: none;
		}
	}
}