
.board-card {
	background-color: #ffffff;
	padding: 5%;
	position: relative;
	box-shadow: 0 0 0 2px #ededed;
	min-height: 16em;

	@include min(650px) {
		padding: 2em;
	}

	@include min(1024px) {
		padding: 3em;
	}

	&__date {
		display: block;
		font-family: $font-headings;
		font-size: rem(22px);
		font-weight: bold;
		color: $color-dark;
		margin: 0 0 .5em 0;
	}

	&__title {
		margin-top: 0;
		margin-bottom: .33em;
		font-size: rem(22px);
		color: inherit;
		font-family: $font-headings;
		font-weight: normal;
		line-height: ( 30 / 22 );
	}
}

@include link(".board-card__link") {
	color: $color-tertiary;
	text-decoration: underline;
}

@include link-over(".board-card__link") {
	color: $color-brand;
	text-decoration: none;
}
