.blender {
	position: relative;
	z-index: 2;
	overflow: hidden;
	padding-bottom: 82%;
	background: $color-dark url(/images/local/blender/v-202503271058/0.jpg) no-repeat 50% 50% / cover;
	margin-top: 1.5rem;
	border-radius: 20px;
	box-shadow: 0 0 20px #e1e1e1;

	&__container {
		position: relative;
		flex-grow: 1;
		display: none;
		padding-bottom: 60px;
		&::after{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			content: "";
			width: 121.1%;
			height: 166px;
			background: transparent url(/images/local/v-202503271058/blender-shadow-bottom.png) no-repeat center center;
			background-size: contain;
		}
		@include min(1024px){
			margin-top: 2.55rem;
		}
	}

	&__description {
		line-height: 1;
		z-index: 4;
		top: 0.8rem;
		font-size: rem(50px);
		font-weight: 300;
		color: #5b5a5a;
		font-family: $font-headings;
		margin: 0;
		text-align: right;

		b,
		strong {
			font-weight: 700;
			color: #383838;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column-reverse;
		gap: 0.7rem;
		position: absolute;
		z-index: 4;
		right: -9%;
		top: 8.4rem;
	}

	&__button {
		border-radius: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		background: transparent none;
		box-shadow: none;
		cursor: pointer;
		padding: 0;

		img {
			background-color: #fff;
			border-radius: 50%;
			transition: background-color .3s ease-in-out;
		}

		&:hover {
			img {
				background-color: #e1e1e1;
			}
		}
	}

	@include min(600px) {
		margin-left: 60px;
		margin-right: 60px;
		&__description{
			margin-right: 60px;
		}
		&__container {
			display: block;
			margin: 0 auto;
			max-width: 650px;
		}
		&__buttons{
			right: 0;
		}
	}
	@include min(1024px){
		&__container {
			margin: 0;
			max-width: none;
		}
	}
	@include min(1300px){
		margin-left: 0;
		margin-right: 0;
		&__description{
			margin-right: 0;
		}
		&__buttons{
			right: -9%;
		}
	}
	@include min(1660px){
		right: -4.25%;
		&__buttons{
			right: -13.4%;
		}
	}


	@keyframes blend-in {
		from {
			display: block;
			opacity: 0;
			//background-position-x: -100%;
		}

		to {
			display: block;
			opacity: 1;
			//background-position-x: 50%;
		}
	}

	&__item {
		@include fill;

		background: {
			position: 50% 50%;
			repeat: no-repeat;
			size: cover;
		}

		&.-animating {
			animation: blend-in 800ms ease-out;
			animation-fill-mode: forwards;
		}
	}
}
